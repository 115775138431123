import React, { useContext, useEffect, useMemo, useState } from "react"
import {
  Card,
  CardBody,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { __deleteApiData, __getApiData, __postApiData } from "constants/apis"
import dataContext from "context/data/dataContext"
import { Link, useParams } from "react-router-dom"
import TableContainer from "components/Common/TableContainer"
import { __formatDate } from "constants/constantfun"
import DeleteModal from "components/Common/DeleteModal"
import EditDetails from "./comp/EditDetails"

const LeadList = props => {
  document.title = "Lead List | Brim Leads"

  const { id, name } = useParams()
  const columns = useMemo(
    () => [
      {
        Header: <div className="text-center">#</div>,
        filterable: false,
        disableFilters: true,
        accessor: "sr_no",
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value}</div>
        },
      },
      {
        Header: "Lead Name",
        accessor: "contact_name",
        filterable: false,
        disableFilters: true,

        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: <div className="text-center">Lead Number</div>,
        accessor: "contact_number",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value || "-"}</div>
        },
      },

      {
        Header: <div className="text-center">Creation Date</div>,
        accessor: "createdAt",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return (
            <div className="text-center">{__formatDate(cellProps.value)}</div>
          )
        },
      },
      {
        Header: <div className="text-center">Lead Status</div>,
        accessor: "lead_status",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value || "-"}</div>
        },
      },
      {
        Header: <div className="text-center">Lead Stage</div>,
        accessor: "lead_stage",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value || "-"}</div>
        },
      },
      {
        Header: <div className="text-center"> Tag</div>,
        accessor: "tag",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value || "-"}</div>
        },
      },
      {
        Header: <div className="text-center"> User Assigned</div>,
        accessor: "user_assigned",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return (
            <div className="text-center">{cellProps.value?.name || "-"}</div>
          )
        },
      },

      {
        Header: <div className="text-center">Action</div>,
        disableFilters: true,
        accessor: "_id",
        Cell: cellProps => {
          const data = cellProps.data.find(elem => elem._id === cellProps.value)

          return (
            <>
              <ul className="list-unstyled hstack gap-1 mb-0 justify-content-center">
                <li>
                  <span
                    className="btn btn-sm btn-soft-info"
                    onClick={() => {
                      updateState({
                        isEdit: true,
                        edit: data,
                      })
                    }}
                  >
                    <i className="bx bx-edit-alt"></i>
                  </span>
                </li>
                <li>
                  <span
                    className="btn btn-sm btn-soft-danger"
                    onClick={() => {
                      updateState({
                        isDelete: true,
                        delete_id: cellProps.value,
                      })
                    }}
                  >
                    <i className="mdi mdi-delete-outline"></i>
                  </span>
                </li>
              </ul>
            </>
          )
        },
      },
    ],
    []
  )
  const { homeOverView, setHomeOverView } = useContext(dataContext)

  const [state, setState] = useState({
    leads: [],
    isLoading: false,
    isDelete: false,
    delete_id: null,
    isEdit: false,
    isAsyncLoading: false,
    edit: null,
  })

  const {
    leads,
    isLoading,
    isDelete,
    delete_id,
    isEdit,
    edit,
    isAsyncLoading,
  } = state
  const updateState = data => setState(state => ({ ...state, ...data }))

  const __handleGetUsers = search => {
    __postApiData(`/api/v1/admin/g_contact`, { campaign: id, search })
      .then(res => {
        if (res.response.response_code === "200") {
          return updateState({
            leads: res.data
              .map((item, i) => ({ ...item, sr_no: i + 1 }))
              .reverse(),
            isLoading: false,
            isAsyncLoading: false,
          })
        }
        return updateState({
          leads: [],
          isLoading: false,
          isAsyncLoading: false,
        })
      })
      .catch(error => {
        console.error(error)
        return updateState({
          leads: [],
          isLoading: false,
          isAsyncLoading: false,
        })
      })
  }

  useEffect(() => {
    updateState({ leads: [], isLoading: true })
    __handleGetUsers("")
  }, [])

  const __handleDeleteLeads = () => {
    // updateState({ isDelete: false, delete_id: null })
    __deleteApiData(`/api/v1/admin/d_contact/${delete_id}`)
      .then(res => {
        if (res.response.response_code === "200") {
          __handleGetUsers()
          return updateState({ isDelete: false, isAsyncLoading: false })
        }
        return updateState({ isLoading: false, isAsyncLoading: false })
      })
      .catch(error => {
        console.error(error)
        return updateState({ isLoading: false, isAsyncLoading: false })
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Campaign - " + name)}
            breadcrumbItem={props.t("Leads")}
          />
          <DeleteModal
            show={isDelete}
            onDeleteClick={__handleDeleteLeads}
            onCloseClick={() =>
              updateState({ isDelete: false, delete_id: null })
            }
          />
          <Card className=" rounded-4  py-3">
            <CardBody>
              <TableContainer
                columns={columns}
                data={leads}
                customPageSize={50}
                isAsyncGlobalFilter
                isAsyncLoading={isAsyncLoading}
                handleAsyncSearch={value => {
                  __handleGetUsers(value)
                  updateState({ isAsyncLoading: true })
                }}
                classNameHeader="dark"
              />
              {leads?.length == 0 && !isLoading && (
                <div className="p-5">
                  <div className="text-center ">
                    <i
                      className="bx bx-search"
                      style={{ fontSize: "30px" }}
                    ></i>
                  </div>
                  <div className="text-center mt-3">No Leads Found</div>
                </div>
              )}
              {isLoading && (
                <div className="p-5">
                  <div className="text-center ">
                    <i
                      className="bx bx-loader bx-spin"
                      style={{ fontSize: "30px" }}
                    ></i>
                  </div>
                </div>
              )}
            </CardBody>
          </Card>

          {editModel()}
        </Container>
      </div>
    </React.Fragment>
  )

  function editModel(params) {
    return (
      <>
        <EditDetails
          isEdit={isEdit}
          edit={edit}
          closeButton={() => updateState({ isEdit: false })}
          completeFun={() => {
            updateState({ isEdit: false })
            __handleGetUsers()
          }}
          campaign={id}
        />
      </>
    )
  }
}

export default withTranslation()(LeadList)
