import React, { Suspense, useContext, useEffect, useState } from "react"
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import CreatableSelect from "react-select/creatable"
//i18n
import { withTranslation } from "react-i18next"
import { __getApiData, __postApiData } from "constants/apis"
import dataContext from "context/data/dataContext"
import { useParams } from "react-router-dom"
const pathId = {
  Feedback: "1",
  Other: "2",
  Reminder: "3",
  Sales: "4",
  Service: "5",
}

const UpdateTag = props => {
  document.title = "Update Tag | Leads"

  const { id, name } = useParams()
  const { homeOverView, setHomeOverView } = useContext(dataContext)
  const [state, setState] = useState({
    newStage: "",
    list: [],
    isLoading: false,
  })
  const { list, newStage, isLoading, _id } = state

  const updateState = data => setState(state => ({ ...state, ...data }))

  const handleOnChange = (value, key, index) => {
    if (index >= 0 && index < list.length) {
      const updatedList = [...list]

      updatedList[index] = {
        ...updatedList[index],
        [key]: value,
      }

      updateState({ list: updatedList })
    }
  }

  useEffect(() => {
    console.log(list)
  }, [list])

  const __handleGetUsers = () => {
    updateState({ isLoading: true })

    __postApiData(`/api/v1/admin/g_stage_transitons`, {
      company_type: pathId[name],
    })
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          updateState({
            isLoading: false,
            list: res.data.list,
            _id: res.data._id,
          })
        } else {
          window.alert(res.response.response_message)
          return updateState({ isLoading: false })
        }
      })
      .catch(error => {
        console.error(error)
        return updateState({ isLoading: false })
      })
  }

  useEffect(() => {
    __handleGetUsers()
  }, [])

  const __handleUpdate = newList => {
    updateState({ isLoading: true })

    __postApiData(`/api/v1/admin/u_stage_transitons`, {
      list: newList || list,
      _id: _id || null,
      company_type: pathId[name],
    })
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          updateState({
            isLoading: false,
            list: res.data.list,
            _id: res.data._id,
          })
        } else {
          window.alert(res.response.response_message)
          return updateState({ isLoading: false })
        }
      })
      .catch(error => {
        console.error(error)
        return updateState({ isLoading: false })
      })
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={props.t(name)} breadcrumbItem={props.t("Tag")} />

          <Card className="rounded-4 ">
            <CardBody>
              <Row className="py-2 border-bottom border-2 mx-2">
                <Col className="ps-0">
                  <CardTitle>Stages & Transitions</CardTitle>
                </Col>
                <Col xl="3" lg={5} md={5} sm="7" className="pe-0">
                  <div className="mb-3 d-flex gap-2">
                    <Input
                      name="name"
                      type="text"
                      placeholder="Add new Stage... "
                      className=" border-0 bg-light rounded-3 p-3"
                      value={newStage}
                      onChange={e => {
                        updateState({ newStage: e.target.value })
                      }}
                    />
                    <span
                      onClick={() => {
                        if (!newStage || newStage.trim() == "") {
                          return
                        }
                        __handleUpdate([
                          ...list,
                          {
                            name: newStage,
                            is_name_editable: true,
                            tags: [],
                            tags_name: "",
                            status: "Open",
                          },
                        ])
                      }}
                      className="btn btn-primary d-flex justify-content-center align-items-center px-4"
                    >
                      Save
                    </span>
                  </div>
                </Col>
              </Row>
              {isLoading && (
                <div className="p-5">
                  <div className="text-center ">
                    <i
                      className="bx bx-loader bx-spin"
                      style={{ fontSize: "30px" }}
                    ></i>
                  </div>
                </div>
              )}

              {list?.map((item, index) => (
                <CardBox
                  key={item._id}
                  name={item.name}
                  tags={item.tags}
                  tags_name={item.tags_name}
                  is_name_editable={item.is_name_editable}
                  status={item.status}
                  handleOnChange={handleOnChange}
                  index={index}
                  __handleUpdate={__handleUpdate}
                />
              ))}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(UpdateTag)

const CardBox = ({
  is_name_editable,
  name,
  tags,
  tags_name,
  status,
  handleOnChange,
  index,
  __handleUpdate,
}) => {
  const [state, setState] = useState({ isshow: false })

  const { isshow } = state
  const updateState = data => setState(prv => ({ ...prv, ...data }))

  const [inputValue, setInputValue] = React.useState("")
  const [value, setValue] = React.useState(tags)

  const handleKeyDown = event => {
    if (!inputValue) return
    switch (event.key) {
      case "Enter":
      case "Tab":
        console.log({ label: inputValue, value: inputValue })
        setValue(prev => [...prev, { label: inputValue, value: inputValue }])

        setInputValue("")
        event.preventDefault()
    }
  }

  useEffect(() => {
    console.log(value)
    handleOnChange(value, "tags", index)
  }, [value])

  return (
    <>
      <Row className="p-3 border border-2 m-2 ">
        <Col className="">
          {(!is_name_editable || !isshow) && (
            <CardTitle className="mb-0 h6">{name}</CardTitle>
          )}
          {isshow && is_name_editable && (
            <Input
              name="name"
              type="text"
              placeholder="Add new Stage... "
              className=" border-0 bg-light rounded-3 p-3"
              value={name}
              onChange={e => {
                handleOnChange(e.target.value, "name", index)
              }}
            />
          )}
        </Col>
        <Col xl="6" lg={7} md={7} sm="8" className="mb-0">
          <div className=" d-flex justify-content-between ">
            {isshow ? (
              <span
                onClick={() => {
                  console.log("first")
                  __handleUpdate()
                }}
                className="btn btn-outline-info ms-auto me-3"
              >
                Save
              </span>
            ) : (
              <span>Tag: {tags_name}</span>
            )}
            <span
              onClick={() => {
                updateState({ isshow: !isshow })
              }}
              style={{ cursor: "pointer" }}
              className={`bx bx-caret-${isshow ? "up" : "down"
                } font-size-20 align-self-center`}
            />
          </div>
        </Col>

        {isshow && (
          <Col sm="12">
            <Row className=" border-top border-1 mt-3 py-3">
              <Col sm="6" className="border-end border-2">
                <CardText className="text-uppercase h5 text-dark">
                  Transitions
                </CardText>

                {["Open", "In-Progress", "Closed"].map((opt, i) => (
                  <div key={i + opt} className="form-check mb-1 ms-4">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={status == opt ? true : false}
                      onClick={() => {
                        handleOnChange(opt, "status", index)
                      }}
                    />
                    <label
                      className="form-check-label"
                      onClick={() => {
                        handleOnChange(opt, "status", index)
                      }}
                    >
                      {opt}
                    </label>
                  </div>
                ))}
              </Col>
              <Col sm="6">
                <CardText className="text-uppercase h5 text-dark">TAG</CardText>
                <CreatableSelect
                  components={{
                    DropdownIndicator: null,
                  }}
                  inputValue={inputValue}
                  isClearable
                  isMulti
                  menuIsOpen={false}
                  onChange={newValue => setValue(newValue)}
                  onInputChange={newValue => setInputValue(newValue)}
                  onKeyDown={handleKeyDown}
                  placeholder="Type something and press enter..."
                  value={value}
                  className="select2-selection border-0 bg-light rounded-0 p-2"
                />
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </>
  )
}
