import React, { useContext, useEffect, useState } from "react"
import { Container } from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { __getApiData } from "constants/apis"
import dataContext from "context/data/dataContext"
import UsersList from "./comp/UsersList"

const Users = props => {
  document.title = "Dashboard Users"
  const { homeOverView, setHomeOverView } = useContext(dataContext)
  const [state, setState] = useState({})
  const { } = state

  const updateState = data => setState(state => ({ ...state, ...data }))

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Setting")}
            breadcrumbItem={props.t("Users")}
          />
          <UsersList />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Users)
