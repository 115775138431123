import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"

import { __getApiData, __postApiData, __putApiData } from "constants/apis"
import QuestionBox from "./comp/QuestionBox"
import Notification from "components/Common/Notification"
import Loader from "components/Common/Loader"

const WalkInForm = props => {
  document.title = "Walk In Form"

  const [state, setState] = useState({
    isLoading: false,
    loadingProgress: 10,
    questions: [],
  })
  const { isLoading, loadingProgress, questions } = state

  const updateState = data => setState(state => ({ ...state, ...data }))

  const __handleGetForm = () => {
    updateState({
      alertMessage: null,
      messageType: null,
      isLoading: true,
      loadingProgress: 20,
    })
    setTimeout(() => {
      updateState({ loadingProgress: 40 })
    }, 100)

    __postApiData(`/api/v1/admin/g_walk_in_form`)
      .then(res => {
        if (res.response.response_code === "200") {
          updateState({
            loadingProgress: 100,
            isLoading: false,
            questions: res.data,
          })
        } else {
          updateState({ loadingProgress: 100, isLoading: false })
        }
      })
      .catch(error => {
        console.error(error)
        updateState({ loadingProgress: 100 })
        setTimeout(() => {
          updateState({ isLoading: false })
        }, 1000)
      })
  }

  useEffect(() => {
    __handleGetForm()
  }, [])

  return (
    <React.Fragment>
      {isLoading && <Loader progress={loadingProgress} />}

      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <Card className="h-100 rounded-4 " style={{ minHeight: "80vh" }}>
                <CardBody>
                  <QuestionBox questionsForm={questions} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default WalkInForm
