import React, { Suspense, useContext, useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Progress,
  Row,
} from "reactstrap"

import { withTranslation } from "react-i18next"
import { __getApiData, __postApiData, __putApiData } from "constants/apis"
import dataContext from "context/data/dataContext"
import { useParams } from "react-router-dom"
import QuestionBox from "./comp/QuestionBox"
import Notification from "components/Common/Notification"
import Loader from "components/Common/Loader"

const HeaderBox = React.lazy(() => import("./comp/HeaderBox"))
const BasicDetails = React.lazy(() => import("./comp/BasicDetails"))
const AdvanceDetails = React.lazy(() => import("./comp/AdvanceDetails"))

const EditCampaigns = props => {
  document.title = "Edit Compaign | Leads"

  const { id } = useParams()
  const { homeOverView, setHomeOverView } = useContext(dataContext)

  const [state, setState] = useState({
    tab: 1,
    campaign: null,
    basic: {
      campaign_name: "",
      campaign_type: "1",
      priority: "",
      campaign_manager: [],
      assignUsers: [],
      distributions_type: "",
    },
    campaign_form: {},
    alertMessage: null,
    messageType: null,
    isLoading: false,
    loadingProgress: 10,
  })
  const {
    campaign,
    tab,
    basic,
    alertMessage,
    messageType,
    isLoading,
    loadingProgress,
    campaign_form,
  } = state

  const updateState = data => setState(state => ({ ...state, ...data }))

  const __handleSubmit = () => {
    const {
      campaign_name,
      campaign_type,
      priority,
      campaign_manager,
      assignUsers,
      distributions_type,
    } = basic

    let alertdata = []
    if (!campaign_name.trim()) {
      alertdata.push("Enter campaign name")
    }
    if (!priority.trim()) {
      alertdata.push("Select priority")
    }
    if (campaign_manager.length == 0) {
      alertdata.push("Select campaign manager")
    }
    if (assignUsers.length == 0) {
      alertdata.push("Assign Users")
    }
    if (!distributions_type) {
      alertdata.push("Select Distribution Setting")
    }
    if (alertdata.length > 0) {
      return updateState({
        alertMessage: alertdata.join(", "),
        messageType: "danger",
      })
    }

    updateState({
      alertMessage: null,
      messageType: null,
      isLoading: true,
      loadingProgress: 20,
    })

    setTimeout(() => {
      updateState({ loadingProgress: 40 })
    }, 100)

    __putApiData(`/api/v1/admin/u_campaigns/${campaign}`, {
      campaign_name,
      campaign_type,
      priority,
      campaign_manager: campaign_manager.map(item => item.value),
      assign_users: assignUsers.map(item => item.value),
      distributions_type,
      campaign,
    })
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          updateState({
            campaign: res.data._id,
            alertMessage: "Campaign Created",
            messageType: "success",
            loadingProgress: 100,
            isLoading: false,
          })
          setTimeout(() => {
            updateState({ alertMessage: null, messageType: null })
          }, 3000)
        } else {
          updateState({ loadingProgress: 100 })
          setTimeout(() => {
            updateState({ isLoading: false })
          }, 1000)
        }
      })
      .catch(error => {
        console.error(error)
        updateState({ loadingProgress: 100 })
        setTimeout(() => {
          updateState({ isLoading: false })
        }, 1000)
      })
  }
  const __handleGetData = () => {
    updateState({
      alertMessage: null,
      messageType: null,
      isLoading: true,
      loadingProgress: 20,
    })

    setTimeout(() => {
      updateState({ loadingProgress: 40 })
    }, 100)

    __postApiData(`/api/v1/admin/g_campaigns_details`, { _id: id })
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          updateState({
            loadingProgress: 100,
            basic: {
              campaign_name: res.data.campaign.campaign_name,
              campaign_type: res.data.campaign.campaign_type,
              priority: res.data.campaign.priority,
              campaign_manager: res.data.campaign.campaign_manager.map(
                manager => ({
                  label: manager.name,
                  value: manager._id,
                })
              ),
              assignUsers: res.data.campaign.assign_users.map(manager => ({
                label: manager.name,
                value: manager._id,
              })),
              distributions_type: res.data.campaign.distributions_type,
            },
            campaign: res.data.campaign._id,
            campaign_form: res.data.campaign_form,
          })
          setTimeout(() => {
            updateState({ isLoading: false })
          }, 1000)
        } else {
          updateState({ loadingProgress: 100 })
          setTimeout(() => {
            updateState({ isLoading: false })
          }, 1000)
        }
      })
      .catch(error => {
        console.error(error)
        updateState({ loadingProgress: 100 })
        setTimeout(() => {
          updateState({ isLoading: false })
        }, 1000)
      })
  }

  useEffect(() => {
    __handleGetData()
  }, [])

  return (
    <React.Fragment>
      {alertMessage && (
        <Notification
          mesage={alertMessage}
          type={messageType}
          closeButton={updateState}
        />
      )}
      {isLoading && <Loader progress={loadingProgress} />}

      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <Card className="h-100 " style={{ minHeight: "80vh" }}>
                <CardBody>
                  <Suspense fallback={<></>}>
                    <HeaderBox
                      tab={tab}
                      campaign={campaign}
                      updateState={updateState}
                    />
                  </Suspense>

                  {tab == 1 && (
                    <>
                      <Suspense fallback={<></>}>
                        <BasicDetails state={basic} updateState={setState} />
                      </Suspense>
                      <Suspense fallback={<></>}>
                        <AdvanceDetails state={basic} updateState={setState} />
                      </Suspense>
                      <div className="d-flex justify-content-end py-4 gap-3 align-items-end">
                        <Button
                          className="btn btn-primary bg-gradient "
                          onClick={__handleSubmit}
                        >
                          Save Draft
                        </Button>
                        <Button
                          className="btn btn-primary bg-gradient "
                          onClick={() => campaign && updateState({ tab: 2 })}
                          disabled={campaign ? false : true}
                        >
                          Next
                        </Button>
                      </div>
                    </>
                  )}

                  {tab == 2 && (
                    <QuestionBox
                      campaign={campaign}
                      campaign_form={campaign_form}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(EditCampaigns)
