import React, { Suspense, useContext, useEffect, useState } from "react"
import { Card, CardBody, Col, Container, Row } from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { __getApiData } from "constants/apis"
import dataContext from "context/data/dataContext"
import { useParams } from "react-router-dom"
import CampainProgress from "./comp/CampainProgress"
import CampainList from "./comp/CampainList"
import CampainTagProgress from "./comp/CampainTagProgress"
const pathId = {
  Feedback: "1",
  Other: "2",
  Reminder: "3",
  Sales: "4",
  Service: "5",
}

const Campaigns = props => {
  document.title = "Compaign | Brim Leads"

  const { id } = useParams()
  const { homeOverView, setHomeOverView } = useContext(dataContext)
  const [state, setState] = useState({})
  const { } = state

  const updateState = data => setState(state => ({ ...state, ...data }))

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Campaigns")}
            breadcrumbItem={props.t(id)}
          />

          <Row>
            <Col md={8}>
              <CampainProgress campaign_type={pathId[id]} />

              <CampainTagProgress campaign_type={pathId[id]} />
            </Col>
            <Col md={4}>
              <CampainList campaign_type={pathId[id]} />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Campaigns)
