import axios from "axios"
import { __apiFormHeader, __apiHeader, __authToken } from "./constantfun"

const __LIVE = process.env.REACT_APP_LIVE
const __LOCAL = process.env.REACT_APP_LIVE
// const __LOCAL = process.env.REACT_APP_LOCAL

export const __HOST = () =>
  process.env.NODE_ENV === "development" ? __LOCAL : __LIVE

function checkIsValid(data) {
  if (data.response.response_code == "401") {
    localStorage.clear()
    sessionStorage.clear()
    window.location.reload()
  }
}

export const __getApiData = async endpoint => {
  try {
    const response = await axios.get(__HOST() + `${endpoint}`, __apiHeader())
    checkIsValid(response.data)
    return response.data
  } catch (error) {
    throw error
  }
}

export const __postApiData = async (endpoint, data) => {
  return await axios
    .post(__HOST() + `${endpoint}`, data, __apiHeader())

    .then(response => {
      checkIsValid(response.data)
      return response.data
    })
    .catch(error => {
      throw error
    })
}
export const __postApiFormData = async (endpoint, data) => {
  return await axios
    .post(__HOST() + `${endpoint}`, data, __apiFormHeader())
    .then(response => {
      checkIsValid(response.data)
      return response.data
    })
    .catch(error => {
      throw error
    })
}

export const __putApiData = async (endpoint, data) => {
  return await axios
    .put(__HOST() + `${endpoint}`, data, __apiHeader())
    .then(response => {
      checkIsValid(response.data)
      return response.data
    })
    .catch(error => {
      throw error
    })
}

export const __deleteApiData = async endpoint => {
  return await axios
    .delete(__HOST() + `${endpoint}`, __apiHeader())
    .then(response => {
      checkIsValid(response.data)
      return response.data
    })
    .catch(error => {
      throw error
    })
}
