import Loader from "components/Common/Loader"
import Notification from "components/Common/Notification"
import { __getApiData, __postApiData, __putApiData } from "constants/apis"
import React, { useEffect, useState } from "react"
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
const stageData = {
  Open: [
    "Open",
    "In-Progress",
    "Converted",
    "Lost",
    "Follow-Up",
    "Interested",
    "Not-Connected",
    "Closed",
  ],
  "In-Progress": ["Follow-Up", "Interested", "Not-Connected"],
  Closed: ["Converted", "Lost", "Closed"],
}

const EditDetails = ({ edit, closeButton, isEdit, completeFun, campaign }) => {
  const [state, setState] = useState({
    leads: null,
    users: [],
    loadingProgress: 10,
    isLoading: false,
    alertMessage: "",
    messageType: "",
  })

  const {
    leads,
    users,
    loadingProgress,
    isLoading,
    alertMessage,
    messageType,
  } = state
  const updateState = data => setState(state => ({ ...state, ...data }))
  const updateLeads = updatedBasic => {
    updateState({
      leads: {
        ...state.leads,
        ...updatedBasic,
      },
    })
  }

  const __handleGetUsers = () => {
    __postApiData(`/api/v1/admin/g_campaign_users`, {
      campaign: edit?.campaign._id,
    })
      .then(res => {
        if (res.response.response_code === "200") {
          updateState({ users: res.data })
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  useEffect(() => {
    edit && __handleGetUsers()
    edit && updateState({ leads: edit })
  }, [edit])

  const __handleUpdateLead = () => {
    const newData = new Object(leads)
    delete newData.createdAt
    delete newData.updatedAt
    delete newData?.sr_no

    updateState({ isLoading: true, loadingProgress: 10, alertMessage: "" })
    setTimeout(() => {
      updateState({ loadingProgress: 40 })
    }, 100)
    __putApiData(`/api/v1/admin/u_contact`, {
      ...newData,
      user_assigned: newData.user_assigned._id,
    })
      .then(res => {
        updateState({
          loadingProgress: 100,
        })
        if (res.response.response_code === "200") {
          completeFun()
          setTimeout(() => {
            updateState({
              alertMessage: res.response.response_message,
              messageType: "success",
              isLoading: false,
            })
          }, 1000)
        } else {
          setTimeout(() => {
            updateState({
              alertMessage: res.response.response_message,
              messageType: "danger",
              isLoading: false,
            })
          }, 1000)
        }
      })
      .catch(error => {
        console.error(error)
        updateState({
          isLoading: false,
          alertMessage: "Update failed!",
          messageType: "danger",
        })
      })
  }

  return (
    <>
      {alertMessage && (
        <Notification
          mesage={alertMessage}
          type={messageType}
          closeButton={updateState}
        />
      )}
      {isLoading && <Loader title={""} progress={loadingProgress} />}
      <Modal isOpen={isEdit} centered size="xl">
        <ModalHeader toggle={closeButton}>Edit Lead</ModalHeader>
        <ModalBody className="rounded-0 p-5">
          <Row>
            <Col md={8} className="border-end border-1 pe-4">
              <div className="mb-3 col-md-6">
                <Label className="form-label">Lead Name:</Label>
                <Input
                  name="name"
                  type="text"
                  placeholder="Name... "
                  className=" border-0 bg-light rounded-0 p-3"
                  value={leads?.contact_name}
                  onChange={e => {
                    updateLeads({ contact_name: e.target.value })
                  }}
                />
              </div>
              <div className="mb-3 col-md-6">
                <Label className="form-label">Lead Number:</Label>
                <Input
                  name="name"
                  type="text"
                  placeholder="Name... "
                  className=" border-0 bg-light rounded-0 p-3"
                  value={leads?.contact_number}
                  onChange={e => {
                    updateLeads({ contact_number: e.target.value })
                  }}
                />
              </div>
              <Row className="border-top border-1 pt-3">
                <div className="mb-3 col-md-6">
                  <Label className="form-label">
                    Alternate Contact Number:
                  </Label>
                  <Input
                    name="name"
                    type="text"
                    placeholder="Name... "
                    className=" border-0 bg-light rounded-0 p-3"
                    value={leads?.alternate_contact_number}
                    onChange={e => {
                      updateLeads({ alternate_contact_number: e.target.value })
                    }}
                  />
                </div>
                <div className="mb-3 col-md-6">
                  <Label className="form-label">Email Address:</Label>
                  <Input
                    name="name"
                    type="text"
                    placeholder="Name... "
                    className=" border-0 bg-light rounded-0 p-3"
                    value={leads?.email_address}
                    onChange={e => {
                      updateLeads({ email_address: e.target.value })
                    }}
                  />
                </div>
              </Row>
            </Col>
            <Col md={4} className="ps-4">
              <div className="mb-4">
                <Label className="form-label">Assignee</Label>
                <select
                  className="form-select border-0 bg-light rounded-0 p-3"
                  name="select"
                  value={leads?.user_assigned._id}
                  onChange={e => {
                    console.log(users.find(user => user._id == e.target.value))
                    updateLeads({
                      user_assigned: users.find(
                        user => user._id == e.target.value
                      ),
                    })
                  }}
                >
                  <option value="">Choose...</option>
                  {users?.map(item => (
                    <option key={item._id} value={item._id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-4">
                <Label className="form-label">Lead Status</Label>
                <select
                  className="form-select border-0 bg-light rounded-0 p-3"
                  name="select"
                  value={leads?.lead_status}
                  onChange={e => {
                    updateLeads({ lead_status: e.target.value })
                  }}
                >
                  <option value="">Choose...</option>
                  {["Open", "In-Progress", "Closed"].map(item => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-3">
                <Label className="form-label">Lead Stage</Label>
                <select
                  className="form-select border-0 bg-light rounded-0 p-3"
                  name="select"
                  value={leads?.lead_stage}
                  onChange={e => {
                    updateLeads({ lead_stage: e.target.value })
                  }}
                >
                  <option value="">Choose...</option>
                  {stageData[leads?.lead_status]?.map(item => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </Col>
          </Row>
          <div className="d-flex gap-2 justify-content-end border-top border-1 pt-3 mt-5">
            <Button
              className="btn btn-outline-primary bg-white text-primary "
              onClick={closeButton}
            >
              Cancel
            </Button>
            <Button
              className="btn bg-primary border-primary "
              onClick={__handleUpdateLead}
            >
              Save
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default EditDetails
