import React from "react"
import { Modal, ModalBody, ModalHeader, Row } from "reactstrap"

const ViewAnswer = ({ closeButton, isEdit, data }) => {
  console.log(data)
  return (
    <>
      <Modal isOpen={isEdit} centered size="xl">
        <ModalHeader toggle={closeButton}>Lead Review</ModalHeader>
        <ModalBody className="rounded-0 p-3">
          {data && data?.length > 0 ? (
            <>
              {data.map((item, index) => (
                <div
                  key={index}
                  className="d-flex mb-2 border-bottom-1  bg-light p-2"
                >
                  <span className="text-primary">Q. {item?.q}:</span>
                  <span className="ms-2 text-dark">
                    Ans: {item?.value || "....."}
                  </span>
                </div>
              ))}
            </>
          ) : (
            "no Data found"
          )}
        </ModalBody>
      </Modal>
    </>
  )
}

export default ViewAnswer
