import React, { useContext, useEffect, useMemo, useState } from "react"
import {
  Card,
  CardBody,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap"

// import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { __deleteApiData, __getApiData, __postApiData } from "constants/apis"
import AddContact from "./comp/AddContact"
import EditDetails from "./comp/EditDetails"
import TableContainer from "components/Common/TableContainer"
import { __formatDate } from "constants/constantfun"
import DeleteModal from "components/Common/DeleteModal"
const pathId = {
  1: "Feedback",
  2: "Other",
  3: "Reminder",
  4: "Sales",
  5: "Service",
}

const Contact = props => {
  document.title = "Contact |  Lead"

  const [state, setState] = useState({
    modal1: false,
    leads: [],
    isLoading: false,
    isDelete: false,
    delete_id: null,
    isEdit: false,
    edit: null,
    isAsyncLoading: false,
  })
  const {
    modal1,
    leads,
    isLoading,
    isDelete,
    delete_id,
    isEdit,
    edit,
    isAsyncLoading,
  } = state

  const updateState = data => setState(state => ({ ...state, ...data }))

  const columns = useMemo(
    () => [
      {
        Header: <div className="text-center">#</div>,
        filterable: false,
        disableFilters: true,
        accessor: "sr_no",
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value}</div>
        },
      },
      {
        Header: "Contact Name",
        accessor: "contact_name",
        filterable: false,
        disableFilters: true,

        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: <div className="text-center">Contact Number</div>,
        accessor: "contact_number",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value || "-"}</div>
        },
      },

      {
        Header: <div className="text-center">Creation Date</div>,
        accessor: "createdAt",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return (
            <div className="text-center">{__formatDate(cellProps.value)}</div>
          )
        },
      },
      {
        Header: <div className="text-center"> Campaign</div>,
        accessor: "campaign",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return (
            <div className="text-center">
              {cellProps.value?.campaign_name || "-"}
            </div>
          )
        },
      },
      {
        Header: <div className="text-center"> Campaign Type</div>,
        accessor: "campaign_type",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return (
            <div className="text-center">{pathId[cellProps.value] || "-"}</div>
          )
        },
      },
      {
        Header: <div className="text-center">Lead Stage</div>,
        accessor: "lead_stage",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value || "-"}</div>
        },
      },

      {
        Header: <div className="text-center"> User Assigned</div>,
        accessor: "user_assigned",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return (
            <div className="text-center">{cellProps.value?.name || "-"}</div>
          )
        },
      },

      {
        Header: <div className="text-center">Action</div>,
        disableFilters: true,
        accessor: "_id",
        Cell: cellProps => {
          const data = cellProps.data.find(elem => elem._id === cellProps.value)

          return (
            <>
              <ul className="list-unstyled hstack gap-1 mb-0 justify-content-center">
                <li>
                  <span
                    className="btn btn-sm btn-soft-info"
                    onClick={() => {
                      updateState({
                        isEdit: true,
                        edit: data,
                      })
                    }}
                  >
                    <i className="bx bx-edit-alt"></i>
                  </span>
                </li>
                <li>
                  <span
                    className="btn btn-sm btn-soft-danger"
                    onClick={() => {
                      updateState({
                        isDelete: true,
                        delete_id: cellProps.value,
                      })
                    }}
                  >
                    <i className="mdi mdi-delete-outline"></i>
                  </span>
                </li>
              </ul>
            </>
          )
        },
      },
    ],
    []
  )
  const __handleGetUsers = search => {
    __postApiData(`/api/v1/admin/g_contacts_list`, { search })
      .then(res => {
        if (res.response.response_code === "200") {
          return updateState({
            leads: res.data
              .map((item, i) => ({ ...item, sr_no: i + 1 }))
              .reverse(),
            isLoading: false,
            isAsyncLoading: false,
          })
        }
        return updateState({
          leads: [],
          isLoading: false,
          isAsyncLoading: false,
        })
      })
      .catch(error => {
        console.error(error)
        return updateState({
          leads: [],
          isLoading: false,
          isAsyncLoading: false,
        })
      })
  }

  useEffect(() => {
    updateState({ leads: [], isLoading: true })
    __handleGetUsers("")
  }, [])

  const __handleDeleteLeads = () => {
    // updateState({ isDelete: false, delete_id: null })
    __deleteApiData(`/api/v1/admin/d_contact/${delete_id}`)
      .then(res => {
        if (res.response.response_code === "200") {
          __handleGetUsers()
          return updateState({ isDelete: false, isAsyncLoading: false })
        }
        return updateState({ isLoading: false, isAsyncLoading: false })
      })
      .catch(error => {
        console.error(error)
        return updateState({ isLoading: false, isAsyncLoading: false })
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* <Breadcrumbs title={props.t("Contact")} breadcrumbItems={[]} /> */}
          <DeleteModal
            show={isDelete}
            onDeleteClick={__handleDeleteLeads}
            onCloseClick={() =>
              updateState({ isDelete: false, delete_id: null })
            }
          />
          <Card className=" rounded-4  py-3">
            <CardBody>
              <TableContainer
                columns={columns}
                data={leads}
                customPageSize={30}
                isAsyncGlobalFilter
                isNextPrvButtonsEnabled={leads?.length != 0}
                isAddUserList={"Upload Contact"}
                handleUserClick={() => {
                  updateState({ modal1: true })
                }}
                handleAsyncSearch={value => {
                  __handleGetUsers(value)
                  updateState({ isAsyncLoading: true })
                }}
                isAsyncLoading={isAsyncLoading}
                classNameHeader="dark"
              />
              {leads?.length == 0 && !isLoading && (
                <div className="p-5">
                  <div className="text-center ">
                    <i
                      className="bx bx-search"
                      style={{ fontSize: "30px" }}
                    ></i>
                  </div>
                  <div className="text-center mt-3">No contact Found</div>
                </div>
              )}
              {isLoading && (
                <div className="p-5">
                  <div className="text-center ">
                    <i
                      className="bx bx-loader bx-spin"
                      style={{ fontSize: "30px" }}
                    ></i>
                  </div>
                </div>
              )}
            </CardBody>
          </Card>
          {editModel()}
        </Container>
      </div>
      {modelBox1()}
    </React.Fragment>
  )
  function modelBox1() {
    return (
      <Modal isOpen={modal1} centered size="xl">
        <ModalHeader
          toggle={() => {
            updateState({ modal1: false })
          }}
        >
          Upload Contact
        </ModalHeader>
        <ModalBody className="rounded-0 p-5">
          <AddContact />
        </ModalBody>
      </Modal>
    )
  }
  function editModel(params) {
    return (
      <>
        <EditDetails
          isEdit={isEdit}
          edit={edit}
          closeButton={() => updateState({ isEdit: false })}
          completeFun={() => {
            updateState({ isEdit: false })
            __handleGetUsers()
          }}
        />
      </>
    )
  }
}

export default withTranslation()(Contact)
