import React, { useContext, useEffect, useMemo, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import Select from "react-select"

//i18n
import { withTranslation } from "react-i18next"
import { __getApiData, __postApiData } from "constants/apis"
import dataContext from "context/data/dataContext"
import { __formatDate, __formatDate2 } from "constants/constantfun"
import TableContainer from "components/Common/TableContainer"
import ViewAnswer from "./ViewAnswer"
const __formatTime = time => {
  const minutes = Math.floor(time / 60)
  const seconds = time % 60
  const formattedMinutes = minutes.toString().padStart(2, "0")
  const formattedSeconds = seconds.toString().padStart(2, "0")
  return `${formattedMinutes}:${formattedSeconds}`
}

const CampaignCallReport = props => {
  const { homeOverView, setHomeOverView } = useContext(dataContext)
  const [state, setState] = useState({
    list: [],
    viewData: null,
    isView: false,
    isLoading: false,
    showFilter: false,
    campaign_type: [],
    from_date: __formatDate2(new Date()),
    to_date: __formatDate2(new Date()),
    campaigns: [],
    campaign_id: null,
  })
  const {
    list,
    isLoading,
    showFilter,
    campaign_id,
    from_date,
    to_date,
    isView,
    viewData,
  } = state

  const OpenLink = (id, name, from_date, to_date) => {
    window.open(
      `/campaign-user-report/${id}/${name}/${from_date}/${to_date}`,
      "_blank"
    )
  }
  const columns = useMemo(
    () => [
      {
        Header: <div className="text-center">#</div>,
        filterable: false,
        disableFilters: true,
        accessor: "sr_no",
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value}</div>
        },
      },
      {
        Header: "Lead Name",
        accessor: "contact_name",
        filterable: false,
        disableFilters: true,

        Cell: cellProps => {
          return <>{cellProps.value || "-"}</>
        },
      },
      {
        Header: "lead Number",
        accessor: "contact_number",
        filterable: false,
        disableFilters: true,

        Cell: cellProps => {
          return <>{cellProps.value || "-"}</>
        },
      },
      {
        Header: "Campaign Name",
        accessor: "campaign_name",
        filterable: false,
        disableFilters: true,

        Cell: cellProps => {
          return <>{cellProps.value || "-"}</>
        },
      },
      {
        Header: "Executive Name",
        accessor: "executive_name",
        filterable: false,
        disableFilters: true,

        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: <div className="text-center">Lead Status</div>,
        accessor: "lead_status",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value || "-"}</div>
        },
      },
      {
        Header: <div className="text-center">Lead Stage</div>,
        accessor: "lead_stage",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value || "-"}</div>
        },
      },
      {
        Header: <div className="text-center"> Time Taken</div>,
        accessor: "time_taken",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return (
            <div className="text-center">
              {__formatTime(cellProps.value || 0)} min
            </div>
          )
        },
      },

      {
        Header: <div className="text-center">Remark</div>,
        disableFilters: true,
        accessor: "remark",
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value}</div>
        },
      },
      {
        Header: <div className="text-center">Not Connected Resion</div>,
        disableFilters: true,
        accessor: "notconnected_resion",
        Cell: cellProps => {
          return <div className="text-center">{cellProps.value || "-"}</div>
        },
      },

      {
        Header: <div className="text-center">Call Time</div>,
        disableFilters: true,
        accessor: "createdAt",
        Cell: cellProps => {
          return (
            <div className="text-center">{__formatDate(cellProps.value)}</div>
          )
        },
      },
      {
        Header: <div className="text-center">Action</div>,
        disableFilters: true,
        accessor: "_id",
        Cell: cellProps => {
          const data = cellProps.data.find(elem => elem._id === cellProps.value)

          return (
            <>
              <ul className="list-unstyled hstack gap-1 mb-0 justify-content-center">
                <li>
                  <button
                    className="btn btn-sm btn-soft-info"
                    onClick={() => {
                      console.log(data)
                      updateState({ isView: true, viewData: data.questions })
                    }}
                  >
                    <i className="mdi mdi-eye-outline"></i>
                  </button>
                </li>
              </ul>
            </>
          )
        },
      },
    ],
    []
  )

  const updateState = data => setState(state => ({ ...state, ...data }))

  const __handleGetUsers = () => {
    updateState({ list: [], isLoading: true })

    __postApiData(`/api/v1/admin/g_campaign_call_report`, {
      campaign: campaign_id,
      from_date: new Date(from_date).valueOf(),
      to_date: new Date(to_date).valueOf(),
    })
      .then(res => {
        if (res.response.response_code === "200") {
          return updateState({
            list: res.data
              .map((item, i) => ({ ...item, sr_no: i + 1 }))
              .reverse(),
            isLoading: false,
          })
        }
        return updateState({ list: [], isLoading: false })
      })
      .catch(error => {
        console.error(error)
        return updateState({ list: [], isLoading: false })
      })
  }

  useEffect(() => {
    __handleGetUsers()
    __getApiData(`/api/v1/admin/g_campaigns_list`)
      .then(res => {
        if (res.response.response_code === "200") {
          updateState({ campaigns: res.data })
        }
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Report")}
            breadcrumbItem={props.t("Campaign Report")}
          />
          <Card className="rounded-4 pb-4">
            <CardBody>
              <TableContainer
                isGlobalFilter
                columns={columns}
                data={list}
                customPageSize={20}
                filterCom={
                  showFilter ? (
                    <FilterBox
                      state={state}
                      updateState={updateState}
                      handleSubmit={__handleGetUsers}
                    />
                  ) : null
                }
                isAddUserList={"Filter"}
                handleUserClick={() => {
                  updateState({ showFilter: !showFilter })
                }}
                isNextPrvButtonsEnabled={list?.length != 0}
                classNameHeader="dark"
              />

              {list?.length == 0 && !isLoading && (
                <div className="p-5">
                  <div className="text-center ">
                    <i
                      className="bx bx-search"
                      style={{ fontSize: "30px" }}
                    ></i>
                  </div>
                  <div className="text-center mt-3">No Report Found</div>
                </div>
              )}

              {isLoading && (
                <div className="p-5">
                  <div className="text-center ">
                    <i
                      className="bx bx-loader bx-spin"
                      style={{ fontSize: "30px" }}
                    ></i>
                  </div>
                </div>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
      {viewModel()}
    </React.Fragment>
  )
  function viewModel(params) {
    return (
      <>
        <ViewAnswer
          isEdit={isView}
          data={viewData}
          closeButton={() => updateState({ isView: false })}
        />
      </>
    )
  }
}

export default withTranslation()(CampaignCallReport)

const FilterBox = ({ state, updateState, handleSubmit }) => {
  const { from_date, to_date, campaign_id, campaigns } = state
  return (
    <Row className="mb-2 ">
      <Col md={"3"} className="">
        <div className="mb-3">
          <Label className="form-label">From Date</Label>
          <Input
            name="name"
            type="date"
            value={from_date}
            placeholder="Name... "
            className=" border-0 bg-light rounded-0 p-3"
            onChange={e => {
              updateState({ from_date: e.target.value })
            }}
          />
        </div>
      </Col>
      <Col md={"3"} className="">
        <div className="mb-3">
          <Label className="form-label">To Date</Label>
          <Input
            min={from_date}
            name="name"
            type="date"
            value={to_date}
            placeholder="Name... "
            className=" border-0 bg-light rounded-0 p-3"
            onChange={e => {
              updateState({ to_date: e.target.value })
            }}
          />
        </div>
      </Col>
      <Col md={"6"} className="">
        {/* <div className="mb-3">
          <label className="control-label">Campaign Type</label>
          <Select
            value={campaign_type}
            isMulti={true}
            onChange={e => {
              console.log(e)
              updateState({ campaign_type: e })
            }}
            options={[
              {
                label: "Feedback",
                value: "1",
              },
              {
                label: "Other",
                value: "2",
              },
              {
                label: "Reminder",
                value: "3",
              },
              {
                label: "Sales",
                value: "4",
              },
              {
                label: "Service",
                value: "5",
              },
            ]}
            className="select2-selection border-0 bg-light rounded-0 p-2"
          />
        </div> */}
        <div className="mb-3">
          <label className="control-label">Campaign</label>

          <select
            className="form-select border-0 bg-light rounded-0 p-3"
            name="select"
            value={campaign_id}
            onChange={e => {
              console.log(e.target.value)
              updateState({ campaign_id: e.target.value })
            }}
          >
            <option value="">Choose Campaign...</option>

            {campaigns?.map(item => (
              <option key={item._id} value={item._id}>
                {item.campaign_name}
              </option>
            ))}
          </select>
        </div>
      </Col>
      <Col sm="12">
        <div className="float-end mb-4 mt-1">
          <Button
            className="me-2 btn bg-secondary  bg-gradient"
            onClick={() => {
              updateState({
                campaign_type: [],
                from_date: "",
                to_date: "",
              })
            }}
          >
            Clear
          </Button>
          <Button
            onClick={handleSubmit}
            className=" btn bg-success  border-success bg-gradient"
          >
            Search
          </Button>
        </div>
      </Col>
    </Row>
  )
}
